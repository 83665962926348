<template>
  <div>
    <v-row>
      <v-breadcrumbs :items="bread" large></v-breadcrumbs>
    </v-row>
    <v-row>
      <v-col lg="12">
        <v-card>
          <v-card-title class="text-center justify-center py-6">
            <h3 class="font-weight-bold display-1">Administrar</h3>
          </v-card-title>
          <v-card-title class="text-center justify-center py-6">
            <h2 class="font-weight-bold display-0">
              {{ operatoria.nombre }}
            </h2>
          </v-card-title>
          <v-card-subtitle class="text-center justify-center py-6">
            <span class="text-muted">{{ estadoTramite }}</span>
          </v-card-subtitle>
          <div hidden>
            {{ mostrarPestania(operatoria.tipo_servicio_id) }}
          </div>
          <template>
            <v-row justify="end" v-if="downloads.length > 0">
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    style="margin-right: 20px; margin-bottom: 10px"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    Descargar
                    <v-icon dark right> mdi-chevron-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    :disabled="item.url === null"
                    @click="verDescargas(item.key, item.url)"
                    v-for="(item, index) in downloads"
                    :key="index"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-row>
            <v-row justify="end">
              <v-btn
                v-if="operatoria.tipo_servicio_id === 20 && estado_id === 11"
                class="error mr-5 mb-3"
                large
                color="success"
                @click="abrirLegajo()"
                >Abrir Legajo</v-btn
              >
            </v-row>
            <v-row justify="end">
              <v-btn
                v-if="liquidaciones.length === 0"
                class="error mr-5 mb-3"
                large
                @click="eliminarLegajo()"
                >Eliminar Legajo</v-btn
              >
            </v-row>
            <ModalBitacora :lineaCreditoCliente_id="legajo"> </ModalBitacora>
            <ModalSincronizarCliTrilay
              v-show="parseInt(rol) === 9 ? false : true"
              :lineaCreditoCliente_id="legajo"
            >
            </ModalSincronizarCliTrilay>
          </template>
          <v-tabs v-model="tab" background-color="transparent" grow>
            <v-tab to="#cliente" href="#cliente"> Cliente </v-tab>
            <v-tab to="#credito" href="#credito">Solicitud </v-tab>
            <v-tab to="#documentacion" href="#documentacion">
              Documentación
            </v-tab>
            <v-tab
              to="#comprobantes"
              href="#comprobantes"
              v-if="
                tipoCreditoTab == 'desembolso' &&
                parseInt(this.subtipo, 10) === 0 &&
                parseInt(rol) !== 9
                  ? true
                  : false
              "
            >
              Comprobante
            </v-tab>
            <v-tab
              to="#liquidaciones"
              href="#liquidaciones"
              v-if="
                operatoria.id !== 71 &&
                tipoCreditoTab == 'desembolso' &&
                liquidaciones.length > 0 &&
                parseInt(this.subtipo, 10) === 0
              "
            >
              Liquidaciones
            </v-tab>
            <v-tab
              to="#liquidacion"
              v-if="
                (operatoria.id !== 71 &&
                  tipoCreditoTab == 'enlace' &&
                  lcc.liquidacion_enlace) ||
                (tipoCreditoTab == 'desembolso' &&
                  parseInt(this.subtipo, 10) === 0 &&
                  liquidaciones.length > 0 &&
                  (parseInt(rol) !== 9 ? true : false))
              "
              href="#liquidacion"
            >
              Liquidación
            </v-tab>
            <v-tab
              to="#liquidacionesFinanciamiento"
              v-if="
                operatoria.id !== 71 &&
                operatoria.tipo_servicio_id === 13 &&
                parseInt(rol) === 2
              "
              href="#liquidacionesFinanciamiento"
            >
              Liquidación
            </v-tab>
            <v-tab
              to="#liquidacionesAnr"
              v-if="operatoria.tipo_servicio_id === 19"
              href="#liquidacionesAnr"
            >
              Liquidación Anr
            </v-tab>
            <v-tab
              to="#desembolsos"
              v-if="operatoria.tipo_servicio_id === 19"
              href="#desembolsos"
            >
              Desembolsos
            </v-tab>
            <v-tab
              to="#bitacora"
              @click="getBitacora()"
              v-if="this.legajo"
              href="#bitacora"
            >
              Bitácora
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="cliente">
              <v-card class="mt-5" flat>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>Legajo</td>
                        <td>{{ legajo || '-' }}</td>
                      </tr>
                      <tr>
                        <td>Apellido</td>
                        <td>{{ cliente.apellido || '-' }}</td>
                      </tr>
                      <tr>
                        <td>Nombre</td>
                        <td>{{ cliente.nombre || '-' }}</td>
                      </tr>
                      <tr>
                        <td>Razon Social</td>
                        <td>{{ cliente.razonSocial || '-' }}</td>
                      </tr>
                      <tr>
                        <td>CUIT/DNI</td>
                        <td>{{ cliente.cuit || cliente.dni || '-' }}</td>
                      </tr>
                      <tr>
                        <td>CBU</td>
                        <td>{{ cliente.cbu || '-' }}</td>
                      </tr>
                      <tr
                        v-if="typeof cliente.sectorProductivo !== 'undefined'"
                      >
                        <td>Sector Productivo</td>
                        <td>{{ cliente.sectorProductivo.nombre }}</td>
                      </tr>
                      <tr>
                        <td>Domicilio Electrónico</td>
                        <td>{{ cliente.domicilioElectronico || '-' }}</td>
                      </tr>
                      <tr>
                        <td>Domicilio Legal</td>
                        <td>{{ cliente.domicilioLegal || '-' }}</td>
                      </tr>
                      <tr>
                        <td>Domicilio Real</td>
                        <td>{{ cliente.domicilioReal || '-' }}</td>
                      </tr>
                      <tr>
                        <td>Celular</td>
                        <td>{{ cliente.celular || '-' }}</td>
                      </tr>
                      <tr>
                        <td>Teléfono Fijo</td>
                        <td>{{ cliente.telefonoFijo || '-' }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-tab-item>
            <v-tab-item value="credito" v-if="this.legajo">
              <v-card class="mt-5" flat>
                <v-card-text>
                  <v-card flat>
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td>Nombre</td>
                            <td>{{ operatoria.nombre || '-' }}</td>
                          </tr>
                          <tr>
                            <td>Descripción</td>
                            <td>{{ operatoria.descripcion || '-' }}</td>
                          </tr>
                          <tr>
                            <td>Sector Productivo</td>
                            <td>
                              {{ operatoria.sectorProductivo.nombre || '-' }}
                            </td>
                          </tr>
                          <tr>
                            <td>Fecha de creación</td>
                            <td>
                              {{
                                moment(operatoria.createdAt).format(
                                  'DD-MM-YYYY'
                                ) || '-'
                              }}
                            </td>
                          </tr>
                          <tr
                            v-for="(item, index) in clienteParametro"
                            :key="index"
                            v-if="operatoria.id !== 71"
                          >
                            <td>{{ item.tipo_parametro_id.nombre }}</td>
                            <td
                              v-if="item.tipo_parametro_id.options === 'float'"
                            >
                              {{ formatter.format(item.valor) }}
                            </td>
                            <td
                              v-if="
                                (item.tipo_parametro_id.options === 'int' ||
                                  item.tipo_parametro_id.options ===
                                    'string') &&
                                item.valor === '1'
                              "
                            >
                              {{ item.valor }}
                            </td>
                            <td
                              v-if="
                                item.tipo_parametro_id.options === 'month' &&
                                item.valor >= 0
                              "
                            >
                              {{ item.valor }} meses
                            </td>
                            <td v-if="item.tipo_parametro_id.options === 'int'">
                              {{ item.valor }}
                            </td>
                            <td
                              v-if="item.tipo_parametro_id.options === 'string'"
                            >
                              {{ item.valor }}
                            </td>
                            <td
                              v-if="
                                item.tipo_parametro_id.options !== 'int' &&
                                item.tipo_parametro_id.options !== 'percent' &&
                                item.tipo_parametro_id.options !== 'float' &&
                                item.tipo_parametro_id.options !== 'string' &&
                                item.tipo_parametro_id.options !== 'month'
                              "
                            >
                              <span v-if="item.valor == 1"> Mensual </span>
                              <span v-if="item.valor == 2"> Bimestral </span>
                              <span v-if="item.valor == 3"> Trimestral </span>
                              <span v-if="item.valor == 6"> Semestral </span>
                              <span v-if="item.valor == 12"> Anual </span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              v-if="
                                tipoCreditoTab == 'enlace' &&
                                !lcc.liquidacion_enlace
                              "
                            >
                              <v-btn
                                block
                                color="primary"
                                @click="liquidarEnlace"
                              >
                                Liquidar
                              </v-btn>
                            </td>
                          </tr>
                          <tr
                            v-if="
                              estadoTramite === 'Análisis' &&
                              !existeAnticipo &&
                              parseFloat(anticipo) !== 0 &&
                              anticipo
                            "
                          >
                            <td>
                              <v-btn
                                block
                                color="primary"
                                @click="liquidarAnticipo"
                              >
                                Liquidar Anticipo
                              </v-btn>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <ModalParametrosActiva
                                :legajoId="legajo"
                                :operatoria="operatoria.fideicomiso_id.id"
                                :linea="operatoria"
                                :cuitCbu="cuitCbu"
                                :estado="lcc.estado_id"
                              >
                              </ModalParametrosActiva>
                            </td>
                          </tr>
                          <tr>
                            <td
                              v-if="
                                tipoCreditoTab == 'desembolso' &&
                                liquidaciones.length === 0 &&
                                subtipo === '3'
                              "
                            >
                              <v-btn
                                block
                                color="primary"
                                @click="liquidarSubtipo"
                              >
                                Liquidar
                              </v-btn>
                            </td>
                          </tr>
                          <tr>
                            <td>Imagen</td>
                            <td>
                              <v-img
                                class="img-fluid"
                                v-bind:src="operatoria.imagenURL"
                                aspect-ratio="1"
                              ></v-img>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="comprobantes">
              <v-card class="mt-5" flat>
                <v-row>
                  <v-col md="8" lg="8" sm="12">
                    <v-card-text>
                      <ListadoComprobantes
                        :comprobantes="comprobantes"
                        @chkComprobantes="setComprobantes"
                        v-if="listadoFlag"
                      ></ListadoComprobantes>
                      <CargaComprobante
                        :estado="estado_id"
                        :ParametrosCliente="clienteParametro"
                        v-if="!listadoFlag"
                      ></CargaComprobante>
                    </v-card-text>
                  </v-col>
                  <v-col md="4" lg="4" sm="12">
                    <v-card flat>
                      <v-card-text>
                        <div v-if="listadoFlag && this.max_liquidacion > 0">
                          <v-alert text color="info">
                            <b
                              >Liquidaciones restantes
                              {{
                                this.max_liquidacion - this.liquidaciones.length
                              }}</b
                            >
                            <br />
                          </v-alert>
                          <v-alert
                            text
                            :color="
                              !montosLiquidados.excede
                                ? 'success'
                                : 'red dark-red'
                            "
                          >
                            <b> Liquidación / Saldo: <br /> </b>
                            ${{ montosLiquidados.liquidados_iva.toFixed(2) }} /
                            ${{ montosLiquidados.restoCompletar.toFixed(2) }}
                          </v-alert>
                        </div>
                        <v-alert
                          v-if="this.max_liquidacion === 0"
                          border="bottom"
                          icon="mdi-close-octagon"
                          type="error"
                          >Falta parametro Max Liquidación</v-alert
                        >
                        <v-btn
                          block
                          color="primary"
                          @click="liquidar"
                          v-if="listadoFlag"
                          :disabled="
                            comprobante.filter((c) => c.sel === true).length <
                              1 || max_liquidacion - liquidaciones.length === 0
                          "
                        >
                          Liquidar comprobantes
                        </v-btn>
                        <v-btn
                          block
                          color="primary"
                          v-if="!listadoFlag"
                          class="mt-5"
                          @click="getComprobantes()"
                          :loading="loadingComprobantes"
                          >Listado de comprobantes</v-btn
                        >
                        <v-btn
                          class="mt-5"
                          @click="listadoFlag = false"
                          block
                          color="primary"
                          v-if="listadoFlag"
                        >
                          Carga de Comprobantes</v-btn
                        >
                        <v-btn
                          @click="cerrarSolicitud()"
                          v-if="
                            estado_id !== 11 &&
                            this.max_liquidacion - this.liquidaciones.length ===
                              0 &&
                            this.max_liquidacion > 0
                          "
                          class="mt-5"
                          block
                          color="warning"
                        >
                          Cerrar Solicitud
                        </v-btn>
                        <br />
                        <v-btn color="info" block @click="informe(1)">
                          Reporte de comprobantes
                        </v-btn>
                        <br />
                        <v-btn color="success" block @click="informe(2)">
                          Reporte de comprobantes Excel
                        </v-btn>
                        <br />
                        <EliminarLiquidaciones
                          :legajo="this.legajo"
                        ></EliminarLiquidaciones>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item value="liquidacion">
              <LiquidacionEnlace
                v-if="monto_enlace !== '' || monto_subtipo !== ''"
                :total="monto_enlace"
                :totalsub="monto_subtipo"
              ></LiquidacionEnlace>
            </v-tab-item>
            <v-tab-item value="liquidaciones">
              <ListadoLiquidaciones
                :key="reRender"
                :liquidaciones="liquidaciones"
              ></ListadoLiquidaciones>
            </v-tab-item>
            <v-tab-item value="liquidacionesAnr" v-if="lcc.estado_id === 6">
              <LiquidacionAnr
                @monto-cargado="getClienteParametros()"
                :key="reRender"
                :legajo="legajo"
              ></LiquidacionAnr>
            </v-tab-item>
            <v-tab-item value="liquidacionesFinanciamiento">
              <LiquidacionFinanciamiento
                :key="reRender"
                :liquidaciones="liquidaciones"
                :gastos="gastos"
                :total="total"
              ></LiquidacionFinanciamiento>
            </v-tab-item>
            <v-tab-item value="desembolsos" v-if="lcc.estado_id === 6">
              <Desembolsos
                v-if="montoAprobado !== null"
                @listar-liquidaciones="getClienteParametros()"
                :montoMaximo="montoAprobado"
                :legajo="legajo"
                :desembolsos="numero_desembolsos"
              ></Desembolsos>
            </v-tab-item>
            <v-tab-item value="documentacion">
              <v-card flat class="mt-5">
                <v-card-text v-if="documentos.length < 1">
                  Cargando documentos
                </v-card-text>
                <v-card-text v-for="(docs, indexs) in documentos" :key="indexs">
                  <h2>Analisis {{ indexs }}</h2>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Documento Original</th>
                          <th class="text-left">Documento Cliente</th>
                          <th class="text-left">Acciones</th>
                          <th class="text-left" v-show="indexs === 'General'">
                            <v-btn
                              :disabled="!docs.some((ele) => ele.habilitado)"
                              @click="aprobarCambiosSeleccionados()"
                              color="success"
                            >
                              Aprobar Documentación
                            </v-btn>
                            <v-checkbox
                              label="Seleccionar Todos"
                              v-model="habilitarTodos"
                              @change="selectAll"
                              color="info"
                            >
                            </v-checkbox>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in docs
                            .filter((el) => {
                              if (
                                (el.cliente !== '' && !el.original.status) ||
                                (el.cliente === '' && el.original.status)
                              ) {
                                return true;
                              }
                              if (el.cliente === '' && !el.original.status) {
                                return false;
                              }

                              return true;
                            })
                            .filter((el) => el.cliente.client !== 0)"
                          :key="item.original.id"
                        >
                          <td
                            :class="{ 'disabled-row': !item.original.status }"
                          >
                            {{ item.original.nombre }}
                          </td>
                          <td
                            :class="{ 'disabled-row': !item.original.status }"
                          >
                            {{ item.cliente.nombre || ' - ' }}
                          </td>
                          <td>
                            <div class="d-flex justify-space-between">
                              <v-btn
                                :disabled="!item.cliente.id"
                                v-on:click="verDoc(item.cliente.url)"
                                color="success"
                              >
                                <v-icon>mdi-magnify</v-icon>
                              </v-btn>
                              <Validacion
                                v-if="
                                  item.cliente.estado === 0 &&
                                  // item.validacionRol &&
                                  item.original.status
                                "
                                :documento="item"
                                :operatoria="operatoria.id"
                              />
                              <v-chip
                                class="ml-2"
                                v-if="item.cliente.estado === 1"
                                color="warning"
                                v-model="item.cliente.estado"
                              >
                                <v-icon>mdi-check</v-icon>
                              </v-chip>
                              <v-chip
                                class="ml-2"
                                v-if="item.cliente.estado === 2"
                                color="success"
                                v-model="item.cliente.estado"
                              >
                                <v-icon>mdi-check</v-icon>
                              </v-chip>
                            </div>
                          </td>
                          <td>
                            <v-checkbox
                              v-if="
                                item.original.etapa.id !== 6 &&
                                indexs === 'General' &&
                                item.cliente !== ''
                              "
                              :disabled="item.cliente.estado === 2"
                              v-model="item.habilitado"
                              :input-value="item.habilitado"
                              color="info"
                              class="mr-20"
                              style="transform: scale(1.2); margin-left: 40px"
                            ></v-checkbox>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>

              <v-row class="my-container">
                <tr>
                  <td>
                    <v-btn
                      :disabled="listDocumentos.length <= 0"
                      style="margin-right: 20px; margin-bottom: 10px"
                      color="primary"
                      v-on:click="descargarDoc(listDocumentos)"
                    >
                      Descargar Zip
                      <v-icon dark right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </v-row>

              <v-row>
                <v-col>
                  <!-- <v-btn
                    block
                    v-if="
                      (estadoTramite == 'Análisis' ||
                        estadoTramite == 'Precalificación' ||
                        estadoTramite == 'Requerimiento') &&
                      !tieneDictamen &&
                      operatoria.nombre !== 'Semilla IV' &&
                      operatoria.nombre !==
                        'ANR fortalecimiento Pyme (CAMARAS)' &&
                      operatoria.nombre !== 'Microcréditos Maipú' &&
                      operatoria.nombre !== 'Docentes Maipú' &&
                      docsSinFiltrar.length > 0 &&
                      this.operatoria.id !== 71 &&
                      docsSinFiltrar.every((el) => el.cliente.estado === 2)
                    "
                    @click="aprobarCambios('Calificación')"
                    color="success"
                  >
                    Calificar
                  </v-btn> -->
                  <v-btn
                    block
                    v-if="
                      (estadoTramite == 'Análisis' ||
                        estadoTramite == 'Precalificación' ||
                        estadoTramite !== 'Calificación') &&
                      !tieneDictamen &&
                      (operatoria.nombre === 'Semilla' ||
                        operatoria.nombre === 'Microcréditos Maipú' ||
                        operatoria.nombre === 'Docentes Maipú') &&
                      docsSinFiltrar.length > 0 &&
                      docsSinFiltrar.every((el) => el.cliente.estado === 2) &&
                      estado_id < 6
                    "
                    @click="aprobarCambios('Aprobado')"
                    color="success"
                  >
                    Aprobar
                  </v-btn>
                  <!-- <v-btn
                    block
                    v-if="
                      (estadoTramite == 'Calificación' ||
                        estadoTramite == 'Requerimiento') &&
                      docsSinFiltrar.length > 0 &&
                      !tieneDictamen &&
                      docsSinFiltrar.every(
                        (el) =>
                          el.cliente.estado === 2 ||
                          el.original.status === false
                      )
                    "
                    @click="aprobarCambios('Aprobado')"
                    color="success"
                  >
                    Aprobar
                  </v-btn> -->
                  <v-btn
                    block
                    v-if="puedeAprobar()"
                    @click="aprobarCambios('Aprobado')"
                    color="success"
                  >
                    Aprobar
                  </v-btn>
                  <v-btn
                    block
                    v-if="puedeObservar()"
                    @click="aprobarCambios('Requerimiento')"
                    color="warning"
                  >
                    Observar
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn
                    block
                    v-if="
                      (docsSinFiltrar.length > 0 &&
                        estadoTramite == 'Análisis') ||
                      estadoTramite == 'Precalificación' ||
                      estadoTramite == 'Calificación' ||
                      estadoTramite == 'Requerimiento'
                    "
                    @click="aprobarCambios('Rechazado')"
                    color="error"
                  >
                    Rechazar
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <SubirDocumento
                    :legajo="legajo"
                    :operatoriaId="operatoria.id"
                    :documentos="documentos"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="bitacora">
              <Bitacora
                :bitacora="bitacora ? bitacora : this.bitacora"
              ></Bitacora>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable camelcase */
import moment from 'moment';
import { API_URL } from '@/common/config';
import Validacion from '@/components/Validacion.vue';
import LiquidarBtn from '@/components/LiquidarBtn.vue';
import EliminarLiquidaciones from '@/components/EliminarLiquidaciones.vue';
import Bitacora from '@/components/Credito/Bitacora.vue';
import CargaComprobante from '@/components/Comprobantes/NuevoComprobante.vue';
import LiquidacionEnlace from '@/components/Comprobantes/LiquidacionEnlace.vue';
import ListadoComprobantes from '@/components/Comprobantes/ListadoComprobantes.vue';
import ListadoLiquidaciones from '@/components/Comprobantes/ListadoLiquidaciones.vue';
import LiquidacionFinanciamiento from '@/components/Comprobantes/LiquidacionFinanciamiento.vue';
import LiquidacionAnr from '@/components/Comprobantes/LiquidacionAnr.vue';
import Desembolsos from '@/components/Comprobantes/Desembolsos.vue';
import ModalBitacora from '@/components/ModalBitacora.vue';
import ModalSincronizarCliTrilay from '@/components/ModalSincronicarTrilay.vue';
import ModalParametrosActiva from '@/components/ModalParametrosActiva.vue';
import JSZip from 'jszip';
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import xlsx from 'xlsx';
import logo from '@/common/logo';
import ModalGastos from '@/components/Gastos/ModalGastos.vue';
import SubirDocumento from '@/components/Credito/SubirDocumento.vue';
import { showSimpleMessage } from '../../common/util';

const axios = require('axios');

export default {
  name: 'Legajo',
  components: {
    Bitacora,
    Desembolsos,
    Validacion,
    ModalBitacora,
    LiquidarBtn,
    EliminarLiquidaciones,
    ModalParametrosActiva,
    LiquidacionAnr,
    LiquidacionFinanciamiento,
    CargaComprobante,
    LiquidacionEnlace,
    ListadoComprobantes,
    ListadoLiquidaciones,
    ModalGastos,
    ModalSincronizarCliTrilay,
    SubirDocumento,
  },
  props: [
    'cliente',
    'operatoria',
    'documentos',
    'docsSinFiltrar',
    'estado_id',
    'downloads',
    'legajo',
  ],
  data: () => ({
    habilitarTodos: false,
    ESTADOS_TRAMITE_APROBACION: [
      'Análisis',
      'Precalificación',
      'Calificación',
      'Requerimiento',
    ],
    cuitCbu: {
      cuit: null,
      cbu: null,
      dni: null,
    },
    estados: ['Rechazado', 'Aprobado', 'Requerimiento'],
    reRender: 0,
    montoAprobado: null,
    listadoFlag: false,
    bitacora: [],
    token: localStorage.getItem('token'),
    rol: localStorage.getItem('rol'),
    tab: 'cliente',
    estadoTramite: null,
    gastos: [],
    oldLegajo: '',
    liquidaciones: [],
    loadDocumentos: false,
    listDocumentos: [],
    EstadoDocumentos: [],
    listaSolicitudEstado: [],
    comprobantes: [],
    comprobante: [],
    bread: [
      {
        text: 'Mendoza Fiduciaria',
        disabled: false,
        href: '/',
      },
      {
        text: 'Ver crédito',
        disabled: true,
        href: '/catalogo',
      },
    ],
    tipoCredito: '',
    credito_id: '',
    mensaje: '',
    loading: false,
    fileOff: true,
    tipo_comprobante: '',
    comprobantesReporte: [],
    nro_comprobante: '',
    neto_iva: '',
    total: '',
    montoTotalSolicitado: 0,
    loadingAprobar: false,
    loadingRechazar: false,
    success: false,
    error: false,
    operId: null,
    tipoCreditoTab: '',
    nombreArchivo: [],
    reintegro_total: 0,
    loadingComprobantes: false,
    clienteParametro: [],
    max_liquidacion: '',
    tieneDictamen: false,
    lcc: '',
    monto_enlace: '',
    monto_subtipo: '',
    liquidacionSub: '',
    subtipo: '',
    existeAnticipo: false,
    anticipo: false,
    numero_desembolsos: 0,
    formatter: new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS',
    }),
  }),
  watch: {
    operatoria() {
      // Renderizar el componente
      this.$forceUpdate();
      this.getClienteParametros();
      this.getGastos();
      if (this.operatoria.tipo_servicio_id !== 13) {
        this.getLiquidaciones();
      }
    },

    subtipo(val) {
      switch (val) {
        case '3':
          // Logica para subtipo 3 (Cultural y Turismo)
          this.clienteParametro = this.clienteParametro.filter(
            (c) => c.tipo_parametro_id.clave !== 'liquidacion_aprobada'
          );

          break;
        default:
          break;
      }
    },
    legajo() {
      // Si el legajo cambia, ejecutar getLegajo()
      if (this.legajo !== this.oldLegajo) {
        this.getLcc();
        this.getBitacora();
        this.oldLegajo = this.legajo;
      }

      // Renderizar el componente
      this.$forceUpdate();
    },
  },
  methods: {
    selectAll() {
      this.documentos.General.forEach((doc) => {
        if (
          doc.cliente !== '' &&
          doc.cliente.estado !== 2 &&
          doc.documento_maestro.tipo_analisis === 'General'
        ) {
          doc.habilitado = this.habilitarTodos;
        }
      });
    },
    puedeAprobar() {
      return (
        !this.tieneDictamen &&
        this.ESTADOS_TRAMITE_APROBACION.includes(this.estadoTramite) &&
        this.docsSinFiltrar.length > 0 &&
        this.docsSinFiltrar.every(
          (el) =>
            el.cliente.estado === 2 ||
            el.original.etapa.id === 6 ||
            !el.original.status
        )
      );
    },
    puedeObservar() {
      return (
        ['Análisis', 'Precalificación', 'Calificación'].includes(
          this.estadoTramite
        ) &&
        this.docsSinFiltrar.length > 0 &&
        this.operatoria.id !== 71 &&
        this.docsSinFiltrar.some((el) => el.cliente.estado === 1)
      );
    },
    puedeObservarDeshabilitado() {
      return this.docsSinFiltrar.some(
        (el) => el.cliente.estado === 0 && el.original.etapa.id === 6
      );
    },
    async abrirLegajo() {
      this.$swal
        .fire({
          title: '¿Esta seguro?',
          text: 'El legajo pasara a estado "Análisis"',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#007e33',
          cancelButtonColor: '#d33',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Confirmar',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const query = {
              method: 'put',
              url: `${API_URL}api/servicio/clientes`,
              headers: {
                token: this.token,
              },
              data: {
                lineaCreditoCliente_id: this.legajo,
                estado: 3,
              },
            };
            const {
              data: { status, message },
            } = await axios(query);
            if (status === 'error') console.error(message);
            if (status === 'success') {
              await this.$swal.fire(
                'Exito!!',
                'El legajo en estado Análisis',
                'success'
              );
              this.estado_id = 3;
              this.getListaSolicitudEstado();
            }
          }
        });
    },
    async liquidarAnticipo() {
      const montoAprobado = this.clienteParametro.find(
        (el) => el.tipo_parametro_id.id === 48
      ).valor;
      const montoAnticipo = montoAprobado * parseFloat(this.anticipo);
      const query = {
        method: 'post',
        url: `${API_URL}api/liquidacion/anticipo`,
        headers: {
          token: this.token,
        },
        data: {
          lccId: this.legajo,
          anticipo: montoAnticipo,
        },
      };
      const {
        data: { status, message },
      } = await axios(query);
      if (status === 'error') console.error(message);
      if (status === 'success') {
        await this.$swal.fire(
          'Liquidado',
          'El anticipo ha sido liquidado.',
          'success'
        );
        this.$router.go(0);
      }
    },
    eliminarLegajo() {
      this.$swal
        .fire({
          title: '¿Esta seguro?',
          text: 'Si borra el legajo no se podra recuperar',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#007e33',
          cancelButtonColor: '#d33',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Eliminar',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const query = {
              method: 'post',
              url: `${API_URL}api/lineaCreditoClientes/eliminarLegajo`,
              headers: {
                token: this.token,
              },
              data: {
                lineaCreditoCliente_id: this.legajo,
              },
            };
            const {
              data: { status, message },
            } = await axios(query);
            if (status === 'error') console.error(message);
            if (status === 'success') {
              await this.$swal.fire(
                'Eliminado!',
                'El legajo a sido eliminado.',
                'success'
              );
              this.$router.push('/');
            }
          }
        });
    },
    moment() {
      return moment();
    },
    async informe(tipo) {
      const query = {
        method: 'post',
        url: `${API_URL}api/comprobante/liquidadosLegajo`,
        headers: {
          token: localStorage.getItem('token'),
        },
        data: {
          lineacreditocliente_id: this.legajo,
        },
      };
      const {
        data: { status, comprobantes },
      } = await axios(query);
      if (status === 'success') this.comprobantesReporte = comprobantes;
      this.exportPdf(tipo);
    },
    exportPdf(tipo) {
      const doc = new JsPDF('l', 'mm', [297, 410]);
      const columnsHands = Object.keys(this.comprobantesReporte[0]).map(
        (el) => {
          const title = el
            .replace('nro', 'Nº')
            .replace('_', ' ')
            .replace(/(^\w|\s\w)/g, (l) => l.toUpperCase());
          return { title, dataKey: el };
        }
      );
      const height = 50;
      const imgData = logo;
      doc.addImage(imgData, 'PNG', 10, 5, 48, 20);
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(18);
      doc.text(`Informe Comprobantes Legajo N° ${this.legajo}`, 15, 30);
      doc.setFontSize(13);
      doc.text(
        `Ticket N° ${
          this.clienteParametro.find((el) => el.tipo_parametro_id.id === 44)
            .valor
        }`,
        15,
        36
      );
      doc.setFontSize(13);
      doc.text(
        `Monto Inversion Aprobada: $ ${
          this.clienteParametro.find((el) => el.tipo_parametro_id.id === 48)
            .valor
        }`,
        15,
        42
      );
      doc.setFontSize(13);
      doc.text(
        `Monto Reintegro: $ ${
          this.clienteParametro.find((el) => el.tipo_parametro_id.id === 45)
            .valor
        }`,
        15,
        48
      );
      doc.setFontSize(13);
      doc.text(`Fecha: ${moment().format('DD/MM/YYYY')}`, 150, 30);
      doc.setFontSize(15);
      doc.text(`Periodo: ${moment().format('DD/MM/YYYY')}`, 15, 54);
      doc.autoTable(columnsHands, this.comprobantesReporte, {
        startY: height,
        columnStyles: { text: { cellWidth: 'auto' } },
      });
      if (tipo === 0) {
        window.open(doc.output('bloburl'), '_blank');
      }
      if (tipo === 1) {
        doc.save(`Informe Comprobantes ${moment().format('DD/MM/YYYY')}.pdf`);
        // this.$emit('update:visibleModalInformeComprobante', false);
      }
      if (tipo === 2) {
        const informes = this.comprobantesReporte.map((el) => {
          const row = columnsHands.reduce((acc, e) => {
            acc[e.title] = el[e.dataKey];
            return acc;
          }, {});
          return row;
        });
        const ws = xlsx.utils.json_to_sheet(informes);
        const wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, 'People');
        xlsx.writeFile(
          wb,
          `Informe Comprobantes ${moment().format('DD/MM/YYYY')}.xlsx`
        );
      }
    },
    setComprobantes(datos) {
      this.comprobante = datos;
    },
    async getClienteParametros() {
      const url = `${API_URL}api/clienteParametros`;
      const header = {
        headers: {
          token: this.token,
        },
      };
      const data = {
        lineaCreditoCliente_id: this.legajo,
      };
      axios.post(url, data, header).then((response) => {
        this.clienteParametro = response.data.ClienteParametro || [];
        // Validando por tipo de operatoria/categoria (tipo_servicio_id) Programa ENLACE

        if (this.liquidaciones.length > 0) {
          this.monto_subtipo = this.liquidaciones[0].total;
        }

        if (this.clienteParametro.length === 0) {
          return;
        }
        const getParametroByClave = (clave) => {
          const foundParam = this.clienteParametro.find(
            (param) => param.tipo_parametro_id.clave === clave
          );

          return foundParam ? foundParam.valor : null;
        };
        this.cuitCbu.dni =
          parseInt(getParametroByClave('dni_representante')) || 0;
        this.cuitCbu.cuit = parseInt(getParametroByClave('cuit')) || 0;
        this.cuitCbu.cbu = getParametroByClave('cbu') || '';

        this.monto_enlace = getParametroByClave('monto_enlace');
        this.total = getParametroByClave('monto');
        this.montoTotalSolicitado = getParametroByClave(
          'monto_total_solicitado'
        );
        const montoAprobado = getParametroByClave('monto_aprobado');

        this.montoAprobado = montoAprobado || null;

        if (response.data.status === 'error') {
          this.$swal.fire(
            'Ocurrió un error',
            'No se pudieron obtener los parametros ingresados por el cliente',
            'warning'
          );
        }
      });

      this.reRender += 1;
    },
    verDoc(url) {
      window.open(url);
    },
    verDescargas(prefix, url) {
      const docUrl = `${API_URL}${prefix}${url}`;
      window.open(docUrl);
    },
    getBitacora() {
      // Deje este if que valida el valor de this.operatoria.id porque a veces viene undefined
      const url = `${API_URL}api/bitacora/${this.legajo}`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: this.token,
        },
      };
      axios.get(url, header).then((response) => {
        this.bitacora = response.data.bitacora.reverse();
      });
    },
    getListaSolicitudEstado() {
      const url = `${API_URL}api/solicitudEstado`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: this.token,
        },
      };
      axios.get(url, header).then((response) => {
        this.listaSolicitudEstado = response.data.solicitudEstado;
        this.getEstadoTramite();
      });
    },
    getEstadoTramite() {
      this.estadoTramite = this.listaSolicitudEstado.filter(
        (el) => el.id === parseInt(this.estado_id, 10)
      )[0].nombre;
    },
    async setEstadoTramite(nombreEstado, comentario = '') {
      const estado = this.listaSolicitudEstado.filter(
        (el) => el.nombre === nombreEstado
      );
      const lineacreditoClienteId = this.legajo;

      const formData = {
        lineaCreditoCliente_id: lineacreditoClienteId,
        estado: estado[0].id,
        cliente_id: this.cliente.id,
        operatoria: this.operatoria.id,
        comentario,
      };
      const header = {
        headers: {
          token: this.token,
        },
      };
      await axios
        .put(`${API_URL}api/servicio/clientes`, formData, header)
        .then((response) => {
          this.cliente = response.data.cliente;
          this.alert = true;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$router.push('/');
    },
    async setEstadoDocumentacion() {
      const promesas = this.documentos.General.filter(
        (doc) => doc.habilitado
      ).map((doc) => {
        const data = {
          observaciones: 'Documento aprobado',
          documentacionCliente_id: doc.cliente.documentacion_id,
          cliente_id: doc.cliente.cliente_id,
          lineaCreditoCliente_id: this.legajo,
        };

        const url = `${API_URL}api/documentacionClientes`;
        const header = {
          headers: {
            'Content-Type': 'application/json',
            token: localStorage.getItem('token'),
          },
        };
        return axios.put(url, data, header);
      });

      try {
        const respuestas = await Promise.all(promesas);
        // Procesar las respuestas de cada petición
        let success = true;
        for (const respuesta of respuestas) {
          if (respuesta.data.status !== 'success') {
            success = false;
            this.error = true;
            this.errorMensaje = respuesta.data.message;
            break; // Salir del bucle si hay un error
          }
        }
        if (success) {
          this.loading = false;
          this.success = true;
          this.error = false;
          this.resetForm();
        }
      } catch (error) {
        console.error('Error en al menos una petición:', error);
        this.loading = false;
        this.error = true;
      } finally {
        this.dialog = false;
        this.$router.go(`${this.$router.currentRoute}#documentacion`);
      }
    },

    async aprobarCambiosSeleccionados() {
      this.$swal
        .fire({
          title: '¿Confirmas los cambios?',
          showCancelButton: true,
          confirmButtonColor: '#388e3c',
          cancelButtonColor: '#d32f2f',
          confirmButtonText: 'Continuar',
          cancelButtonText: 'Salir',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.setEstadoDocumentacion();
          }
        });
    },
    async aprobarCambios(nombreEstado) {
      let comentario = '';

      if (this.estados.includes(nombreEstado)) {
        const { value: inputValue } = await this.$swal.fire({
          title: `Motivo`,
          input: 'textarea',
          inputLabel: `Ingresa el motivo: ${nombreEstado}`,
          inputPlaceholder: 'Escribe aquí...',
          showCancelButton: true,
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
        });

        if (inputValue) {
          comentario = inputValue;
          console.log(comentario);
        } else if (nombreEstado === 'Rechazado' && inputValue.trim() === '') {
          showSimpleMessage('Alerta', 'Debe ingresar un motivo', 'info', 3000);
          return;
        }
      }
      this.$swal
        .fire({
          title: '¿Confirmas los cambios?',
          showCancelButton: true,
          confirmButtonColor: '#388e3c',
          cancelButtonColor: '#d32f2f',
          confirmButtonText: 'Continuar',
          cancelButtonText: 'Salir',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.setEstadoTramite(nombreEstado, comentario);
          }
        });
    },
    async mostrarPestania(tipoServicioId) {
      if (tipoServicioId !== null && typeof tipoServicioId !== 'undefined') {
        switch (tipoServicioId) {
          case 13:
            this.tipoCreditoTab = 'credito';
            break;
          case 20:
            this.tipoCreditoTab = 'desembolso';
            break;
          case 23:
            this.tipoCreditoTab = 'enlace';
            break;
          default:
            this.tipoCreditoTab = '';
        }
      }
    },
    async getComprobantes() {
      this.loadingComprobantes = true;
      const url = `${API_URL}api/comprobantes/${this.legajo}`;
      const header = {
        headers: {
          token: this.token,
        },
      };
      await axios.get(url, header).then((r) => {
        this.comprobantes = r.data.comprobantes.map((c) => ({
          ...c,
          sel: false,
        }));
        this.listadoFlag = true;
        this.loadingComprobantes = false;
      });
    },
    async liquidar() {
      this.$swal
        .fire({
          title:
            '¿Esta seguro que desea liquidar los comprobantes seleccionados?',
          showDenyButton: true,
          confirmButtonText: 'Liquidar',
          denyButtonText: 'Cancelar',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loadingLiquidar = true;
            const url = `${API_URL}api/desembolso/liquidar`;
            const header = {
              headers: {
                token: this.token,
              },
            };
            const data = {
              comprobantes: this.comprobante.filter(
                (c) => c.sel === true && typeof c.id === 'number'
              ),
              lineaCreditoCliente_id: this.legajo,
              lineaCredito_id: this.operatoria.id,
            };
            await axios.post(url, data, header).then((r) => {
              if (r.data.status === 'error') {
                this.$swal.fire(r.data.message, '', 'error');
              } else {
                this.$swal
                  .fire({
                    title: 'Se ha realizado la liquidación',
                    confirmButtonText: 'Aceptar',
                  })
                  .then(async (rdo) => {
                    if (rdo.isConfirmed) {
                      // this.$router.go(`${this.$router.currentRoute.path}#liquidaciones`);
                      this.$router.push('#liquidaciones');
                      this.$router.go(0);
                    }
                  });
              }
            });
          }
        });
    },
    async cerrarSolicitud() {
      const url = `${API_URL}api/solicitud/cambiarestado`;
      const data = {
        lineaCreditoCliente_id: this.legajo,
        estado: 11,
      };
      const header = {
        headers: {
          token: this.token,
        },
      };
      this.$swal
        .fire({
          title: '¿Esta seguro que desea cerrar la Solicitud?',
          showDenyButton: true,
          confirmButtonText: 'Cerrar',
          denyButtonText: 'Cancelar',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            axios.post(url, data, header).then((r) => {
              this.$swal.fire(r.data.message, '', 'success');
              this.$router.go(`${this.$router.currentRoute}#liquidaciones`);
            });
          }
        });
    },
    async getLiquidaciones() {
      // this.loadingLiquidar = true;
      const url = `${API_URL}api/desembolso/liquidaciones/${this.legajo}`;
      const header = {
        headers: {
          token: this.token,
        },
      };
      await axios.get(url, header).then((r) => {
        this.liquidaciones = r.data.liquidaciones;
        this.existeAnticipo = this.liquidaciones
          .map(({ anticipo }) => anticipo)
          .includes(true);
      });
      this.reRender += 1;
    },
    async getGastos() {
      const query = {
        method: 'post',
        url: `${API_URL}api/gastos/cc`,
        headers: {
          token: this.token,
        },
        data: {
          lineacreditocliente_id: this.legajo,
        },
      };
      const {
        data: { status, gastos },
      } = await axios(query);

      if (status === 'success') this.gastos = gastos;
      this.reRender += 1;
    },
    getServicioParametro() {
      if (typeof this.operatoria.id !== 'undefined') {
        axios
          .get(
            `${API_URL}api/servicioParametros/operatoria/${this.lcc.lineaCredito_id}`,
            {
              headers: {
                token: localStorage.getItem('token'),
              },
            }
          )
          .then((response) => {
            const { parametros } = response.data;
            try {
              const parametroValues = {};
              parametros.forEach((param) => {
                const clave = param.tipo_parametro_id.clave;
                parametroValues[clave] = param.valor;
              });

              this.max_liquidacion = parametroValues['max_liquidacion'] || 0;
              this.reintegro_total = parametroValues['reintegro'] || 0;
              this.anticipo = parametroValues['anticipo'] || 0;
              this.numero_desembolsos = parametroValues['desembolsos_anr'] || 0;
              this.subtipo = parametroValues['subtipo_mza_activa'] || 0;
            } catch (err) {
              console.log(err);
              this.max_liquidacion = 0;
            }
          })
          .catch((e) => {
            console.info(e);
          });
      }
    },
    existeDictamen() {
      const url = `${API_URL}api/solicitud/existeDictamen/${this.legajo}`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      axios.get(url, header).then((r) => {
        this.tieneDictamen = r.data.dictamen;
      });
    },
    async getLcc() {
      const url = `${API_URL}api/servicioClientes/${this.legajo}`;
      const { data } = await axios.get(url, {
        headers: {
          token: localStorage.getItem('token'),
        },
      });
      const [lcc] = data.lineaCreditoCliente;
      this.lcc = lcc;
      this.operId = lcc.lineaCredito_id;
      this.getServicioParametro();
      this.getDocumentacion();
    },
    async liquidarEnlace() {
      const url = `${API_URL}api/solicitud/liquidacionEnlace/${this.legajo}`;
      const { data } = await axios.get(url, {
        headers: {
          token: localStorage.getItem('token'),
        },
      });
      this.lcc = data.lineaCreditoCliente;
    },
    async liquidarSubtipo() {
      const url = `${API_URL}api/solicitud/liquidacionSubtipo`;
      const concepto =
        'Comprobante Generico para Mendoza Activa II: Cultura y Turismo';
      const neto = this.clienteParametro.find(
        (e) => e.tipo_parametro_id.clave === 'monto_desembolso'
      ).valor;
      const header = {
        headers: {
          token: this.token,
        },
      };
      const data = {
        lineaCreditoCliente_id: this.legajo,
        neto,
        concepto,
      };
      axios.post(url, data, header).then((response) => {
        this.lcc = response;
        this.monto_subtipo = neto;
        if (response.data.status === 'error') {
          this.$swal.fire(
            'Ocurrió un error',
            'No se pudieron obtener los parametros ingresados por el cliente',
            'warning'
          );
        } else {
          this.$swal
            .fire({
              title: 'Se ha realizado la liquidación',
              confirmButtonText: 'Aceptar',
            })
            .then(async (rdo) => {
              if (rdo.isConfirmed) {
                this.$router.go(0);
              }
            });
        }
      });
    },
    async descargarDoc(listDocumentos) {
      //  Crea un objeto JSZip
      const zip = new JSZip();
      const apellido =
        this.cliente.razonSocial.toUpperCase() ||
        this.cliente.apellido.toUpperCase();
      // Por cada archivo en la lista
      for (const archivo of listDocumentos) {
        const formatUrl = archivo.cliente.url;
        //nombre q tiene el archivo en bd
        const nombreArchivo = archivo.cliente.nombre || 'sin nombre';
        // Agrega el archivo al archivo ZIP
        zip.file(
          nombreArchivo,
          await fetch(formatUrl).then((response) => response.blob())
        );
      }

      // Genera el contenido del archivo ZIP
      const zipContent = await zip.generateAsync({ type: 'blob' });
      // Crea un enlace para descargar el archivo ZIP
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(zipContent);
      downloadLink.download = `Doc.-${apellido}-${this.legajo}.zip`;
      downloadLink.click();
    },
    getDocumentacion() {
      const url = `${API_URL}api/documentacionClientes/servicio/${this.operId}/${this.legajo}`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: this.token,
        },
      };
      axios.get(url, header).then((response) => {
        const rol = localStorage.getItem('rol');

        if (!response.data.listaDocumentosCliente) {
          console.log('No posee doc.');
          this.listDocumentos = [];
          return;
        }
        const docs = response.data.listaDocumentosCliente
          .filter((el) => el.documento_maestro)
          .map((el) => {
            let validationRol;
            if (!el.documento_maestro) {
              return;
            }
            if (rol === '5') {
              validationRol = el.documento_maestro.tipo_analisis === 'Legal';
            } else {
              validationRol = el.documento_maestro.tipo_analisis !== 'Legal';
            }

            return {
              ...el,
              validacionRol: validationRol,
            };
          });

        this.listDocumentos = docs;
        // this.EstadoDocumentos = _.groupBy(
        //   docs,
        //   (d) => d.documento_maestro.tipo_analisis
        // );
      });
    },
  },
  computed: {
    montosLiquidados() {
      let liquidados_iva = this.liquidaciones.map((el) => el.total_iva);
      if (liquidados_iva.length !== 0) {
        liquidados_iva = liquidados_iva.reduce((a, b) => a + b);
      } else {
        liquidados_iva = 0;
      }
      let comprobantes_iva = this.comprobantes.filter(
        (el) => el.rechazado === false
      );
      if (comprobantes_iva.length !== 0) {
        comprobantes_iva = comprobantes_iva
          .map((el) => el.neto_iva)
          .reduce((a, b) => a + b);
      } else {
        comprobantes_iva = 0;
      }
      const liquidacion_aprabada = this.clienteParametro.find(
        (el) => el.tipo_parametro_id.clave === 'liquidacion_aprobada'
      );
      const total_liquidacion = liquidacion_aprabada
        ? liquidacion_aprabada.valor
        : 0;
      const sumLiquidadoYsumComprobantes = liquidados_iva + comprobantes_iva;
      const restoCompletar = total_liquidacion - sumLiquidadoYsumComprobantes;
      if (sumLiquidadoYsumComprobantes > total_liquidacion) {
        return { liquidados_iva, excede: true, restoCompletar };
      }
      return { liquidados_iva, excede: false, restoCompletar };
    },
  },

  created() {
    this.getLcc();
    this.getLiquidaciones();
    this.getGastos();
    this.getListaSolicitudEstado();
    this.getBitacora();
    this.getClienteParametros();
    this.existeDictamen();
    this.oldLegajo = this.legajo;
    this.operId = this.operatoria.id;
    this.getDocumentacion();
  },
};
</script>
<style>
.swal2-title,
.swal2-content,
.swal2-styled {
  font-family: Roboto, sans-serif !important;
}
.my-container {
  display: flex;
  justify-content: flex-end;
}

.disabled-row {
  opacity: 0.5;
  pointer-events: none;
}
</style>
