import { render, staticRenderFns } from "./CreditoView.vue?vue&type=template&id=02ee10e8"
import script from "./CreditoView.vue?vue&type=script&lang=js"
export * from "./CreditoView.vue?vue&type=script&lang=js"
import style0 from "./CreditoView.vue?vue&type=style&index=0&id=02ee10e8&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VContainer,VIcon})
